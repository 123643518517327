import Custom500 from './500';
import { Metadata, PageContainer, CMSModule } from '@/components';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import nextI18nConfig from '../next-i18next.config';
import { setPageId, setRobot, wrapper, setHideNavigation, setNoHeroModuleBgColor } from '@/store/index';
import Header from '@/modules/Header';
import BannerAlertsModule from '@/modules/BannerAlertsModule';
import SmoothScroller from '@/modules/SmoothScroller';
import FooterVertical from '@/modules/FooterVertical';
import Script from 'next/script';
import { useRouter } from 'next/router';
import { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import { getHost, getProtocol } from '@/utils';

const Index = ({
  errorCode,
  errorMessage,
  metadata,
  moduleContent,
  footer,
  header,
  homeHeroAnimationCookie,
  isRobot,
}) => {
  const router = useRouter();
  const bannerRef = useRef(null);
  const [bannerHeight, setBannerHeight] = useState(0);

  const measureBannerHeight = () => {
    setTimeout(() => {
      if (bannerRef.current) {
        const height = bannerRef?.current?.clientHeight;
        setBannerHeight(height);
      } else {
        setBannerHeight(0);
      }
    }, 1000);
  };

  useEffect(() => {
    measureBannerHeight();

    const handleResize = () => {
      measureBannerHeight();
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    measureBannerHeight();

    const handleRouteChange = (url) => {
      measureBannerHeight();

      if (window?.dataLayer?.push) {
        window.dataLayer.push({
          event: 'pageview',
          page: {
            path: url,
            title: metadata.pageTitle,
          },
        });
      }
    };

    router.events.on('routeChangeComplete', handleRouteChange);

    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router]);

  useEffect(() => {
    if (router.asPath.includes('#')) {
      const hash = router.asPath.split('#')[1];

      if (hash.length > 1) {
        const element = document.getElementById(hash);

        if (element) {
          setTimeout(() => {
            window.scrollTo({
              top: element.offsetTop,
              behavior: 'smooth',
            });
          }, 100);
        }
      }
    }
  }, [router]);

  if (errorCode) {
    return <Custom500 errorCode={errorCode} errorMessage={errorMessage} />;
  }

  const newModuleList = moduleContent.filter((m) => m.moduleName !== 'BannerAlertsModule');

  return (
    <>
      {metadata && <Metadata metadata={metadata} isRobot={isRobot} />}

      {moduleContent[0]?.moduleName === 'BannerAlertsModule' && (
        <BannerAlertsModule
          ref={bannerRef}
          data={moduleContent[0]?.props}
          className={classNames(
            'z-10 fixed top-0 left-0 w-full',
            moduleContent[0]?.moduleName === 'BannerAlertsModule' && bannerHeight === 0
              ? 'opacity-0 transition-all duration-1000'
              : 'opacity-1 transition-all duration-1000',
          )}
          onBannerClose={measureBannerHeight}
        ></BannerAlertsModule>
      )}

      {header && (
        <Header
          header={header}
          metadata={metadata}
          top={bannerHeight}
          filledHeader={moduleContent && moduleContent[0] && moduleContent[0].moduleName !== 'HeroCenterAlignedModule'}
        />
      )}
      <SmoothScroller>
        <PageContainer>
          <div className="page-container-content">
            {newModuleList?.map((module) => {
              if (module.moduleName === 'HomeHeroModule') {
                return (
                  <CMSModule
                    key={module.moduleId}
                    module={module}
                    logo={header?.logoElement.logo}
                    animationCookie={homeHeroAnimationCookie}
                  />
                );
              }

              return <CMSModule key={module.moduleId} module={module} />;
            })}
          </div>
          {footer && <FooterVertical data={footer} />}
        </PageContainer>
      </SmoothScroller>
      <Script
        src="https://sdk.selfbook.com/selfbook.js?hotelId=9403&apiKey=0xoQGFM9rk5AT2UP-lDc9-rFKUKEsyTDBaM"
        id="selfbook_jssdk"
        defer
      />

      {/* <StyledCursor /> */}
    </>
  );
};

export default Index;

export const getServerSideProps = wrapper.getServerSideProps((store) => async (context) => {
  const { resolvedUrl, locale, defaultLocale, res, req } = context;
  const host = getHost(req);
  const protocol = getProtocol(req);

  // workaround for missing assets spamming the API
  if (resolvedUrl.startsWith('/_next/')) {
    res.statusCode = 404;
    res.end();
    return;
  }

  let errorCode,
    errorMessage = null;

  const { cookies } = req;

  try {
    const fetchUrl = new URL(`${protocol}//${host}/api${defaultLocale !== locale ? '/' + locale : ''}${resolvedUrl}`);
    fetchUrl.pathname = fetchUrl.pathname.endsWith('/') ? fetchUrl.pathname.slice(0, -1) : fetchUrl.pathname;

    const dataRes = await fetch(fetchUrl.href, {
      headers: { membership: cookies.membership },
      keepalive: true,
      redirect: 'manual',
    });

    errorCode = dataRes?.ok ? false : dataRes?.status;

    const contentType = dataRes?.headers.get('content-type');

    if (errorCode === 500) {
      errorMessage =
        process.env.NODE_ENV === 'development'
          ? 'BE Internal Server Error'
          : "An error has occurred and we're working to fix the problem.";
      res.statusCode = 500;
      console.error('[MP] BE Internal Server Error');
    } else if (!contentType || contentType.indexOf('application/json') === -1) {
      if (errorCode === 404) {
        errorMessage = 'Page not found';
        res.statusCode = 404;
      } else if (errorCode >= 300 && errorCode < 400) {
        console.warn('Redirecting to:', dataRes.headers.get('Location'));
        res.writeHead(errorCode, {
          Location: dataRes.headers.get('Location'),
        });
        res.end();
      } else {
        errorCode = 500;
        errorMessage =
          process.env.NODE_ENV === 'development'
            ? 'BE response not JSON Object'
            : "An error has occurred and we're working to fix the problem.";
        res.statusCode = 500;
        const responseText = await dataRes.text();
        console.error('[MP] BE response not JSON Object. contentType:', contentType, 'responseText:', responseText);
      }
    } else if (!errorCode || errorCode === 404) {
      const data = await dataRes.json();

      if (data?.metadata?.pageType === 'sp404PageNotFound' && res) {
        res.statusCode = 404;
      }
      const isRobot = !!req.headers['is-robot'];
      store.dispatch(setRobot(isRobot));

      res.setHeader(
        'Cache-Control',
        `public, max-age=${resolvedUrl.includes('isPreview=true') || data?.metadata?.doNotCache || data?.header === null || cookies.membership !== undefined ? 0 : process.env.CDN_CACHE_MAX_AGE || 7200}`,
      );

      const homeHeroAnimationCookie = cookies.animation_cookie;

      if (data?.metadata) {
        store.dispatch(setPageId(data.metadata.pageId));

        if (!homeHeroAnimationCookie) {
          store.dispatch(setHideNavigation(data.metadata.pageType === 'homePage'));
        }
      }

      const translations = await serverSideTranslations(locale, ['common'], nextI18nConfig);

      if (data?.moduleContent && data.moduleContent.length > 0) {
        if (data.moduleContent[0].moduleName === 'NoHeroModule') {
          store.dispatch(setNoHeroModuleBgColor(data.moduleContent[0].props.backgroundColour));
        } else {
          store.dispatch(setNoHeroModuleBgColor(null));
        }
      }

      return {
        props: {
          ...data,
          ...translations,
          homeHeroAnimationCookie: homeHeroAnimationCookie ? homeHeroAnimationCookie : null,
          isRobot: !!req.headers['is-robot'],
        },
      };
    }
  } catch (error) {
    errorCode = 500;
    console.error('[MP]', error);
    errorMessage = error.message;
    res.statusCode = 500;
  }

  return {
    props: { errorCode, errorMessage },
  };
});
